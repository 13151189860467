import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import Head from "../components/head"

import baseStyles from "./base.module.scss"
import errorStyles from "./error.module.scss"

const NotFound = () => {
  return (
    <>
      <Head title="Uh-oh🚪🏃‍♂️" />
      <Layout>
        <div className={baseStyles.wrapper}>
          <div className={baseStyles.container}>
            <ul>
              <li className={baseStyles.listItem}>
                <div className={errorStyles.splash}>
                  <div className={errorStyles.overlay} />
                  <div className={errorStyles.aligner}>
                    <h1>Ah nuts...</h1>
                    <h2>
                      We're definitely lost!{" "}
                      <span role="img" aria-label="Grimace Emoji">
                        😬
                      </span>
                      <br />
                      We should probably leave
                      <br />
                      before anyone finds out.
                    </h2>
                  </div>
                </div>
              </li>
              <li className={baseStyles.listItem}>
                <h2>404</h2>
                <p>
                  Look, you're either a savvy developer that's trying to break
                  the website, or I screwed up. Either way, I'd love if you let
                  me know. Maybe
                  <AniLink className={errorStyles.link} to="/contact" fade>
                    {" get in touch "}
                  </AniLink>
                  and tell me what a dummy I am. Maybe you're actually a hiring
                  manager, in which case... Well it was nice knowing you. <br />{" "}
                  <br />
                  <AniLink className={errorStyles.link} to="/" fade>
                    {" Return home "}
                    <span role="img" aria-label="Exit Emojis">
                      🚪🏃‍♂️
                    </span>
                  </AniLink>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NotFound
